<template>
  <v-autocomplete
    v-model="modelValue"
    v-bind="$attrs"
    :items="items"
    :loading="isLoading"
    :search-input.sync="search"
    item-value="API"
    ref="autocomplete"
    hide-details="auto"
    dense
    auto-select-first
    :item-text="displayProp"
    :placeholder="placeholder"
    :label="label"
    :error-messages="errorMessages"
    return-object
    hide-selected
    hide-no-data
    outlined
  >
    <template v-slot:no-data>
      <v-list-item>
        <v-list-item-title>
          Search for your favorite
          <strong>Cryptocurrency</strong>
        </v-list-item-title>
      </v-list-item>
    </template>

    <template #item="data" v-if="hasRenderSlot">
      <slot name="render" :item="data.item"></slot>
    </template>
  </v-autocomplete>
</template>

<script>
import axiosIns from '@/libs/http/http';
export default {
  props: [
    'url',
    'params',
    'displayProp',
    'label',
    'placeholder',
    'errorMessages',
    'value',
    'renderSlot',
    'modifyItems',
  ],
  mounted() {
    // let autocompleteInput = this.$refs.autocomplete.$refs.input;
    // autocompleteInput.addEventListener('focus', this.onFocus, true);
  },
  data: () => ({
    descriptionLimit: 60,
    entries: [],
    isLoading: false,
    model: null,
    search: null,
  }),
  created() {
    this.getData();
  },
  methods: {
    onFocus(e) {
      // this.$refs.autocomplete.isMenuActive = true; // open item list
    },
    getData() {
      if (!this.url) return;
      // Items have already been loaded
      if (this.items.length > 0) return;
      // Items have already been requested
      if (this.isLoading) return;

      this.isLoading = true;
      // Lazily load input items
      axiosIns
        .get(this.url, { params: this.params })
        .then((response) => {
          if (this.modifyItems) {
            this.entries = this.modifyItems(response.data.items);
          } else this.entries = response.data.items;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => (this.isLoading = false));
    },
  },
  computed: {
    modelValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.model = value;
      },
    },
    items() {
      const returnData = this.entries.map((entry) => {
        return Object.assign({}, entry, {});
      });
      return returnData;
    },
    hasRenderSlot() {
      // this.$slots['render']; undefiend when pass named-slot
      const needrenderSlot = this.renderSlot != undefined;
      return !!needrenderSlot;
    },
  },
  watch: {
    search(val) {
      this.getData();
    },
    model(val) {
      this.$emit('onChange', val);
    },
    url() {
      this.entries = [];
      this.getData();
    },
  },
};
</script>
