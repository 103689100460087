<template>
  <div class="mx-2 d-flex align-center">
    <slot name="button" :toggle="toggleShow" />

    <v-dialog
      v-model="dialog"
      transition="scroll-y-transition"
      :width="wdith"
      :persistent="isLoading"
    >
      <v-card class="base-container">
        <!-- Header -->
        <v-toolbar dark color="secondar">
          <v-btn icon dark @click="dialog = false" :disabled="isLoading">
            <v-icon>mdi-close</v-icon>
          </v-btn>

          <v-toolbar-title>{{ title }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-progress-circular v-if="isLoading" indeterminate />
        </v-toolbar>

        <v-card-text class="base-container__body">
          <slot name="body" />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: ['title', 'wdith', 'isLoading'],
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    toggleShow() {
      this.dialog = !this.dialog;
    },
  },
  watch: {
    dialog(newValue) {
      if (newValue) this.$emit('onShow');
    },
  },
};
</script>

<style lang="scss">
.base-container {
  height: 85vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  & > * {
    flex: 0;
  }
  &__body {
    flex: 1;
    overflow: auto;
    scroll-behavior: smooth;
  }
}
</style>
