<template>
  <v-row v-if="showCreatedInfo || showUpdatedInfo">
    <template v-if="isCanRead">
      <AuditLogDetails
        :url="url"
        :params="params"
        :colsName="colsName"
        :renderSlot="renderSlot"
        :entitiesLabel="entitiesLabel"
      >
        <template #button="{ toggle }">
          <v-btn icon @click="toggle">
            <v-icon large color="primary"> mdi-update </v-icon>
          </v-btn>
        </template>

        <template #originalValue="{ item, value }">
          <slot name="originalValue" :item="item" :value="value" />
        </template>
        <template #newValue="{ item, value }">
          <slot name="newValue" :item="item" :value="value" />
        </template>
      </AuditLogDetails>
    </template>
    <template v-if="showCreatedInfo">
      <div>
        <v-card-subtitle>تاريخ الانشاء</v-card-subtitle>
        <v-card-text>
          {{ createdDate }}
        </v-card-text>
      </div>
      <div v-show="false">
        <v-card-subtitle> منشأ المحتوى </v-card-subtitle>
        <v-card-text> {{ createdUsername }} </v-card-text>
      </div>
    </template>

    <template v-if="showUpdatedInfo">
      <div>
        <v-card-subtitle> تاريخ اخر التعديل</v-card-subtitle>
        <v-card-text>
          {{ updatedDate }}
        </v-card-text>
      </div>
      <div v-show="false">
        <v-card-subtitle>معدل المحتوى </v-card-subtitle>
        <v-card-text> {{ updatedUsername }} </v-card-text>
      </div>
    </template>
  </v-row>
</template>

<script>
import { defaultDateFormat } from '@/utls/jsCommon';
import AuditLogDetails from './AuditLogDetails.vue';
import PermissionMixin from '@/mixins/PermissionMixin';
import { appPermissions } from '@/libs/auth/permissions/appPermissions';
export default {
  components: {
    AuditLogDetails,
  },
  props: [
    'url',
    'params',
    'creationTime',
    'creationUsername',
    'lastModificationTime',
    'lastModificationUsername',
    'colsName',
    'renderSlot',
    'entitiesLabel',
  ],
  mixins: [PermissionMixin(appPermissions.audit)],
  computed: {
    showCreatedInfo() {
      return !!this.creationTime;
    },
    showUpdatedInfo() {
      return !!this.lastModificationTime;
    },
    createdDate() {
      if (!this.creationTime) return '';
      return defaultDateFormat(this.creationTime);
    },
    createdUsername() {
      if (!this.creationUsername) return '';
      return this.creationUsername;
    },
    updatedDate() {
      if (!this.lastModificationTime) return '';
      return defaultDateFormat(this.lastModificationTime);
    },
    updatedUsername() {
      if (!this.lastModificationUsername) return '';
      return this.lastModificationUsername;
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped></style>
