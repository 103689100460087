<template>
  <div>
    <v-list-item-content class="pa-0">
      <div class="d-flex py-3 px-4 subtitle-2 white--text" :class="color">
        <span v-if="type == 'add'">انشاء</span>
        <span v-else-if="type == 'update'">تعديل</span>
        <span v-else-if="type == 'delete'">حذف</span>
        <span v-else-if="type == 'recovery'">الغاء الحذف</span>
        <span v-else-if="type == 'save'">حفظ</span>
        <span v-if="label">{{ '-' + label }}</span>
        <v-spacer />
        <span>{{ changeTime }}</span>
        <v-spacer />
        <span>{{ userName }}</span>
      </div>
    </v-list-item-content>

    <v-card elevation="0" outlined v-if="type == 'update'">
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="propertyChanges"
          :items-per-page="1000"
          :loading="false"
          :ripple="false"
          hide-default-footer
          no-data-text="لا يوجد"
        >
          <template v-slot:[`item.originalValue`]="{ item }">
            <slot
              v-if="renderSlot"
              name="originalValue"
              :item="item"
              :value="getValue(item.originalValue)"
            />
            <span v-else>{{ getValue(item.originalValue) }}</span>
          </template>

          <template v-slot:[`item.newValue`]="{ item }">
            <slot
              v-if="renderSlot"
              name="newValue"
              :item="item"
              :value="getValue(item.newValue)"
            />
            <span v-else>{{ getValue(item.newValue) }}</span>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  props: ['change', 'color', 'type', 'renderSlot', 'label'],
  data() {
    return {
      logs: [],
      headers: [
        { text: 'الحقل', value: 'displayPropertyName' },
        { text: 'القيمة قبل التعديل', value: 'originalValue' },
        { text: 'القيمة بعد التعديل', value: 'newValue' },
      ],
      options: {},
    };
  },
  computed: {
    chnageLog() {
      return this.change || {};
    },
    userName() {
      const { userName } = this.chnageLog;
      return userName;
    },
    changeTime() {
      const { entityChange } = this.chnageLog;
      const { changeTime } = entityChange;
      return new Date(changeTime).toLocaleString();
    },
    propertyChanges() {
      const { entityChange } = this.chnageLog;
      const { propertyChanges } = entityChange;
      return propertyChanges || [];
    },
    hasPropertyChanges() {
      return this.propertyChanges.length > 0;
    },
  },
  methods: {
    getValue(value) {
      if (value == 'true') return 'نعم';
      else if (value == 'false') return 'كلا';
      else if (value == 'null') return '';
      return value;
    },
  },
};
</script>

<style lang="scss" scoped></style>
